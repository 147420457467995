<template>
  <div class="match-wrapper" id="ranking-board">
    <div class="match-box bg-color-black">
      <div class="info-box">
        <div class="title">面试岗位匹配</div>
        <div class="text">
          <span>所学专业：</span>
          新媒体技术专业
        </div>
        <div class="text">
          <span>从事企业规模推荐：</span>
          规模100~499人
        </div>
        <div class="text"><span>合适从事岗位及匹配度：</span></div>
        <dv-scroll-ranking-board class="rank-box" :config="ranking" :color="['red']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ranking: {
        data: [
          {
            name: '新媒体运营',
            value: 100
          },
          {
            name: '新媒体文案策划',
            value: 95
          },
          {
            name: '新媒体网络推广',
            value: 78
          },
          {
            name: '互联网产品策划',
            value: 60
          },
          {
            name: '网络营销主管',
            value: 78
          },
          {
            name: '网站运营',
            value: 80
          }
        ],
        carousel: 'single',
        unit: '%'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.match-wrapper
  width: 100%
  height: auto
  padding: 16px
  box-sizing: border-box
  .match-box
    width: 100%
    height: auto
    .info-box
      padding: 16px
      box-sizing: border-box
      color: #fff
      .title
        margin-bottom: 15px
        font-size: 20px
      .text
        margin-bottom: 5px
        font-size: 14px
      .rank-box
        margin-top: 16px
        height: 300px
</style>
