<template>
  <div class="video-wrapper">
    <div class="video-box bg-color-black">
      <div class="video-content">
        <video
          id="video"
          class="video"
          :src="callBackObj.answer_video_url[videoPlayIndex].url"
          controls="controls"
        ></video>
        <div v-if="!videoPlayState" @click="onPlayVideoFn" class="video-cover">
          <div class="hint">
            <div class="text">面试视频回放</div>
            <div class="btn">
              <img src="@/assets/image/play-icon2.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="select-box">
        <div class="title">问题节点:</div>
        <ul>
          <li
            v-for="(item, index) in callBackObj.answer_video_url"
            :class="{ active: videoPlayIndex === index }"
            @click="onSelectVideoPlayFn(index)"
            :key="index"
          >
            {{ index + 1 }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoPlayIndex: 0, // 视频播放序号
      videoPlayState: false // 视频播放状态
    }
  },
  methods: {
    // 播放视频
    onPlayVideoFn() {
      const videoObj = document.getElementById('video')
      videoObj.play()
      videoObj.ontimeupdate = () => {
        if (videoObj.paused) {
          this.videoPlayState = false
        } else {
          this.videoPlayState = true
        }
      }
    },
    // 选择播放视频
    onSelectVideoPlayFn(index) {
      this.videoPlayIndex = index
      setTimeout(() => {
        this.onPlayVideoFn()
      }, 500)
    }
  },
  props: {
    callBackObj: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
.video-wrapper
  width: 100%
  height: auto
  padding: 16px
  box-sizing: border-box
  .video-box
    width: 100%
    height: auto
    .video-content
      position: relative
    .video-content, .video
      width: 100%
      height: 380px
      border-radius: 10px
      .video
        position: absolute
        top: 0
        left: 0
      .video-cover
        position: absolute
        top: 0
        left: 0
        z-index: 999
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        height: 100%
        background: rgba(233, 233, 233, 0.85)
        border-radius: 4px
        cursor: pointer
        opacity: 1
        .hint
          font-size: 18px
          font-weight: 600
          color: #383a4d
          text-align: center
          .btn
            width: 40px
            height: 52px
            margin: 15px auto
            img
              width: 100%
              height: 100%
          .duration
            font-size: 14px
    .video:focus
      outline: none
    .select-box
      width: 100%
      height: auto
      padding: 20px 0 0 16px
      box-sizing: border-box
      border-radius: 0 0 10px 10px
      .title
        padding-bottom: 15px
        font-size: 18px
        color: #fff
      ul
        display: flex
        flex-wrap: wrap
        width: 100%
        height: auto
        margin: 0 auto
        li
          width: 30px
          height: 30px
          margin: 0 15px 15px 0
          font-size: 14px
          border-radius: 50%
          line-height: 30px
          font-weight: 600
          text-align: center
          cursor: pointer
          color: #878A99
          background-color: #F1F3F7
        .active
          color: #fff
          background-color: #3248FF
</style>
