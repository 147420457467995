<template>
  <div class="ability-wrapper" id="croll-board">
    <div class="ability-box bg-color-black">
      <div class="info-box">
        <div class="title">学习能力</div>
        <div class="desc">该生求职课程学习情况展示</div>
        <dv-scroll-board class="table-box" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['课程名称', '学习次数', '学习总时长', '回答次数', '回答正确率', '近期学习'],
        data: [
          ['简历撰写', '8次', '2小时50分钟', '21次', '88%', '2023/03/08'],
          ['职场高效工作法', '6次', '2小时41分钟', '15次', '91%', '2023/02/19'],
          ['面试心态', '5次', '2小时36分钟', '12次', '79%', '2023/03/07'],
          ['逻辑思维', '4次', '2小时22分钟', '11次', '72%', '2023/01/29'],
          ['零基础word教程', '3次', '1小时54分钟', '9次', '66%', '2023/02/05'],
          ['面试礼仪', '3次', '1小时32分钟', '6次', '85%', '2023/07/15'],
          ['PPT实例教程', '2次', '1小时22分钟', '6次', '60%', '2023/03/04'],
          ['护航校招：面试全流程', '2次', '1小时03分钟', '4次', '88%', '2023/04/03'],
          ['职场生存法则', '2次', '0小时06分钟', '3次', '98%', '2023/02/01'],
          ['面试形式', '1次', '0小时35分钟', '2次', '60%', '2023/01/23']
        ],
        index: true,
        align: ['center'],
        rowNum: 5,
        headerBGC: 'rgba(1,225,251,0.4)',
        headerHeight: 50,
        indexHeader: '序号',
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)',
        columnWidth: [50, 160, 80, 110, 80, 95, 105]
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.ability-wrapper
  width: 100%
  height: auto
  padding: 16px
  box-sizing: border-box
  .ability-box
    width: 100%
    height: auto
    .info-box
      padding: 16px
      box-sizing: border-box
      color: #fff
      .title
        font-size: 20px
      .desc
        margin: 15px 0
        font-size: 14px
        color: rgba(255, 255, 255, 0.8)
      .table-box
        width: 100%
        height: 450px
        text-align: center
</style>
