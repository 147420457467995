<template>
  <div class="score-wrapper">
    <div class="score-box bg-color-black">
      <div class="title">面试评分</div>
      <div class="info-box">
        <div class="score">
          <div class="text">新媒体运营岗位</div>
          <div class="num">
            <span>{{ callBackObj.total_score }}</span>
            分
          </div>
        </div>
        <div class="chart-box">
          <div class="item">
            <div ref="ring1" style="height: 160px; width: 160px" />
            <div class="text">专业知识</div>
            <div class="num">
              <span>{{ callBackObj.score1 }}</span>
              分
            </div>
          </div>
          <div class="item">
            <div ref="ring2" style="height: 160px; width: 160px" />
            <div class="text">语言描述</div>
            <div class="num">
              <span>{{ callBackObj.score2 }}</span>
              分
            </div>
          </div>
          <div class="item">
            <div ref="ring3" style="height: 160px; width: 160px" />
            <div class="text">面试态度</div>
            <div class="num">
              <span>{{ callBackObj.score3 }}</span>
              分
            </div>
          </div>
        </div>
      </div>
      <dv-decoration-10 class="line" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'

export default {
  data() {
    return {}
  },
  mounted() {
    this.initChart1()
    this.initChart2()
    this.initChart3()
  },
  methods: {
    initChart1() {
      // 初始化echart
      let chart1 = echarts.init(this.$refs.ring1)
      const option = {
        title: {
          text: '专业',
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 22,
            color: '#3BFF72'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['70%', '80%'],
            center: ['50%', '50%'],
            hoverAnimation: false,
            color: ['#00bcd44a', 'transparent'],
            label: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: this.callBackObj.score1,
                itemStyle: {
                  normal: {
                    color: '#3BFF72',
                    shadowBlur: 10,
                    shadowColor: '#97e2f5'
                  }
                }
              },
              {
                value: 100 - this.callBackObj.score1
              }
            ]
          }
        ]
      }
      chart1.setOption(option)
    },
    initChart2() {
      // 初始化echart
      let chart2 = echarts.init(this.$refs.ring2)
      const option = {
        title: {
          text: '语言',
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 22,
            color: '#ff6800'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['70%', '80%'],
            center: ['50%', '50%'],
            hoverAnimation: false,
            color: ['#00bcd44a', 'transparent'],
            label: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: this.callBackObj.score2,
                itemStyle: {
                  normal: {
                    color: '#ff6800',
                    shadowBlur: 10,
                    shadowColor: '#97e2f5'
                  }
                }
              },
              {
                value: 100 - this.callBackObj.score2
              }
            ]
          }
        ]
      }
      chart2.setOption(option)
    },
    initChart3() {
      // 初始化echart
      let chart3 = echarts.init(this.$refs.ring3)
      const option = {
        title: {
          text: '态度',
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 22,
            color: '#fcc61c'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['70%', '80%'],
            center: ['50%', '50%'],
            hoverAnimation: false,
            color: ['#00bcd44a', 'transparent'],
            label: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: this.callBackObj.score3,
                itemStyle: {
                  normal: {
                    color: '#fcc61c',
                    shadowBlur: 10,
                    shadowColor: '#97e2f5'
                  }
                }
              },
              {
                value: 100 - this.callBackObj.score3
              }
            ]
          }
        ]
      }
      chart3.setOption(option)
    }
  },
  props: {
    callBackObj: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
.score-wrapper
  width: 100%
  height: auto
  .score-box
    position: relative
    padding: 16px 8px 20px 16px
    box-sizing: border-box
    margin-bottom: 10px
    .title
      padding-bottom: 15px
      font-size: 20px
    .info-box
      display: flex
      .score
        width: 100px
        padding-top: 54px
        box-sizing: border-box
        text-align: center
        .text
          font-size: 20px
          margin-bottom: 3px
        .num
          color: #3BFF72
          span
            font-size: 34px
      .chart-box
        flex: 1
        display: flex
        justify-content: space-between
        .item
          text-align: center
          .text
            margin-top: -5px
            font-size: 16px
            color: rgba(255, 255, 255, 0.6)
          .num
            margin-top: 3px
            font-size: 20px
    .line
      position: absolute
      left: 0
      bottom: 0
      width: 100%
      height: 5px
</style>
