<template>
  <div class="score-wrapper">
    <div class="score-box bg-color-black">
      <div class="title">面试情况</div>
      <div class="desc">该生面试题目的分数情况</div>
      <div class="chart-box">
        <div ref="line" style="width: 100%; height: 250px" />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'

export default {
  data() {
    return {}
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      // 初始化echart
      let chart = echarts.init(this.$refs.line)
      let lists = []
      this.callBackObj.answer_video_score.forEach((item, index) => {
        lists.push(`第${index + 1}题`)
      })
      const option = {
        xAxis: [
          {
            type: 'category',
            splitLine: { show: false },
            axisTick: { show: false },
            data: lists
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            min: 0,
            max: 100
          }
        ],
        series: [
          {
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(128, 255, 165)'
                },
                {
                  offset: 1,
                  color: 'rgba(1, 191, 236)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: this.callBackObj.answer_video_score
          }
        ],
        grid: {
          top: '30',
          left: '10',
          right: '10',
          bottom: '5',
          containLabel: true
        }
      }
      chart.setOption(option)
    }
  },
  props: {
    callBackObj: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
.score-wrapper
  width: 100%
  height: auto
  .score-box
    padding: 16px
    box-sizing: border-box
    margin-bottom: 10px
    .title
      font-size: 20px
    .desc
      margin: 15px 0 30px
      font-size: 14px
      color: rgba(255, 255, 255, 0.8)
    .info-box
      display: flex
      .score
        padding: 50px 30px 0 16px
        box-sizing: border-box
        text-align: center
        .text
          font-size: 18px
        .num
          color: #3BFF72
          span
            font-size: 34px
      .chart-box
        flex: 1
        display: flex
        justify-content: space-between
        .item
          text-align: center
          .text
            margin-top: -5px
            font-size: 16px
            color: rgba(255, 255, 255, 0.6)
          .num
            margin-top: 3px
            font-size: 20px
</style>
