<template>
  <div
    :class="loading ? 'index-wrapper add-fixed' : 'index-wrapper'"
    id="screen"
  >
    <div class="screen-wrapper">
      <img class="bg" src="@/assets/image/bg.jpg" />
      <dv-loading v-if="loading" class="loading">
        <img class="bg" src="@/assets/image/bg.jpg" />
        <div class="loading-text" @click="backFn">载入报告...</div>
        <div class="success-btn" ><el-button type="success" plain @click="backFn">返回</el-button></div>
      </dv-loading>
      <div v-if="callBackObj && !loading" class="content">
        <vue-particles
          color="#fff"
          :particleOpacity="0.7"
          :particlesNumber="60"
          shapeType="circle"
          :particleSize="4"
          linesColor="#fff"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.4"
          :linesDistance="150"
          :moveSpeed="2"
          :hoverEffect="true"
          hoverMode="grab"
          :clickEffect="true"
          clickMode="push"
          class="lizi"
        ></vue-particles>
        <div class="top-box">
          <dv-decoration-10 class="line-left" />
          <div class="title-box">
            <dv-decoration-8 :color="['#01E1FB', 'tranparent']" class="line" />
            <div class="title">
              <span
                @click="onSkipPageFn('/interview/welcome')"
                class="title-text"
                >学生面试报告</span
              >
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#01E1FB', 'tranparent']"
              class="line"
            />
          </div>
          <dv-decoration-10 class="line-right" />
          <div class="back-btn" @click="onSkipPageFn('/interview/welcome')">返回</div>
        </div>
        <div class="main-box">
          <div class="main-left">
            <div class="bottom-space">
              <dv-border-box-12>
                <report-video :callBackObj="callBackObj" />
              </dv-border-box-12>
            </div>
            <div class="bottom-space">
              <dv-border-box-12>
                <report-match />
              </dv-border-box-12>
            </div>
          </div>
          <div class="main-right">
            <div class="item-box">
              <div class="item-left">
                <report-score :callBackObj="callBackObj" />
                <report-practice :callBackObj="callBackObj" />
              </div>
              <dv-border-box-1 class="item-right">
                <report-ability />
              </dv-border-box-1>
            </div>
            <div class="box-height">
              <dv-border-box-13>
                <report-job :callBackObj="callBackObj" />
              </dv-border-box-13>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ReportVideo from '@/components/studentReportVideo'
import ReportMatch from '@/components/studentReportMatch'
import ReportScore from '@/components/studentReportScore'
import ReportPractice from '@/components/studentReportPractice'
import ReportAbility from '@/components/studentReportAbility'
import ReportJob from '@/components/studentReportJob'

import * as echarts from 'echarts/core'
import { TitleComponent, GridComponent } from 'echarts/components'
import { PieChart, LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use([
  TitleComponent,
  GridComponent,
  PieChart,
  LineChart,
  CanvasRenderer
])

let timer = null

export default {
  data() {
    return {
      loading: true, // loading状态
      submitObj: {
        channel: ''
      },
      callBackObj: null
    }
  },
  created() {
    if (this.$route.query.channel)
      this.submitObj.channel = this.$route.query.channel
    this.getInterviewResultFn()
  },
  methods: {
    ...mapActions(['getInterviewResult']),
    // 获取面试结果
    async getInterviewResultFn(id) {
      clearInterval(timer)
      const obj = await this.getInterviewResult({
        channel: this.submitObj.channel
      })
      if (obj) this.loading = false
      if (obj && this.callBackObj) {
        if (obj.id !== this.callBackObj.id) {
          this.loading = true
          this.callBackObj = null
          setTimeout(() => {
            this.callBackObj = obj
            this.loading = false
          }, 500)
        }
      } else if (!this.callBackObj) this.callBackObj = obj
      timer = setInterval(() => {
        this.getInterviewResultFn()
      }, 5000)
    },
    // 跳转页面
    onSkipPageFn(url) {
      if (this.channel) {
        this.$router.push(`${url}?channel=${this.channel}`)
      } else {
        this.$router.push(`${url}`)
      }
    },
    //点击返回按钮
    backFn() {
      this.$router.push('/interview/welcome')
    },
  },
  components: {
    ReportVideo,
    ReportMatch,
    ReportScore,
    ReportPractice,
    ReportAbility,
    ReportJob
  }
}
</script>

<style lang="stylus" scoped>
.index-wrapper
  width 100%
  height 100%
  overflow hidden
  &.add-fixed
    position fixed
  .screen-wrapper
    position relative
    width 100%
    height 100%
    .bg
      position absolute
      left 0
      top 0
      bottom 0
      z-index -1
      width 100%
      height 100%
    .loading
      display flex
      align-items center
      justify-content center
      .loading-text
        cursor pointer
        font-weight bold
        line-height 100px
        font-size 30px
        opacity 0.8
        color #fff
      .success-btn
        text-align center
  .content
    position relative
    width 100%
    height 100%
    .lizi
      position absolute
      left 0
      top 0
      bottom 0
      z-index -1
      width 100%
      height 100%
    .top-box
      position relative
      width 100%
      height auto
      display flex
      align-items center
      padding-top 10px
      .line-left, .line-right
        width 30%
        height 4px
      .title-box
        flex 1
        display flex
        align-items center
        position relative
        top 14px
        width 100%
        .title
          flex 1
          font-size 30px
          text-align center
          color #fff
        .line
          width 20%
          height 30px
      .back-btn
        position absolute
        right 45px
        top 42px
        padding 5px 15px
        font-size 16px
        z-index 99
        cursor pointer
        border 1px solid #fff
        border-radius 8px
        color #fff
    .main-box
      display flex
      width 100%
      height auto
      padding 0 20px
      box-sizing border-box
      .main-left
        width 340px
        height auto
      .main-right
        flex 1
        padding-left 10px
        box-sizing border-box
        margin-top 30px
        color #fff
        .item-box
          display flex
          .item-left
            flex 1
            padding-right 10px
            box-sizing border-box
          .item-right
            flex 4
            height 616px
        .box-height
          height 240px
</style>
