<template>
  <div class="ability-wrapper">
    <div class="ability-box bg-color-black">
      <div class="info-box">
        <div class="title">就业去向</div>
        <div class="desc">该生毕业后就业去向跟踪展示</div>
        <div class="list-box">
          <div class="item">
            <div class="title">任职企业</div>
            <div class="text">{{ callBackObj.company_info.company }}</div>
          </div>
          <div class="item">
            <div class="title">任职岗位</div>
            <div class="text">{{ callBackObj.company_info.jobs }}</div>
          </div>
          <div class="item">
            <div class="title">月薪</div>
            <div class="text">{{ callBackObj.company_info.salary }}</div>
          </div>
          <div class="item">
            <div class="title">行业类型</div>
            <div class="text">{{ callBackObj.company_info.industry }}</div>
          </div>
          <div class="item">
            <div class="title">人员规模</div>
            <div class="text">{{ callBackObj.company_info.scale }}</div>
          </div>
          <div class="item">
            <div class="title">企业类型</div>
            <div class="text">{{ callBackObj.company_info.company_type }}</div>
          </div>
          <div class="item">
            <div class="title">企业联系电话</div>
            <div class="text">{{ callBackObj.company_info.tel }}</div>
          </div>
          <div class="item">
            <div class="title">企业信用代码</div>
            <div class="text">{{ callBackObj.company_info.credit_code }}</div>
          </div>
          <div class="item">
            <div class="title">地址</div>
            <div class="text">{{ callBackObj.company_info.address }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    callBackObj: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
.ability-wrapper
  width: 100%
  height: 100%
  padding: 20px 16px
  box-sizing: border-box
  .ability-box
    width: 100%
    height: 100%
    .info-box
      padding: 16px
      box-sizing: border-box
      color: #fff
      .title
        font-size: 20px
      .desc
        margin: 15px 0 30px
        font-size: 14px
        color: rgba(255, 255, 255, 0.8)
      .list-box
        display: flex
        .item
          padding: 8px 20px
          box-sizing: border-box
          border-right: 3px solid rgba(1, 225, 251, 0.4)
          .title
            font-size: 16px
            margin-bottom: 8px
          .text
            font-size: 14px
            color: rgba(255, 255, 255, 0.7)
        .item:first-child
          padding-left: 0
        .item:last-child
          padding-right: 0
          border-right: none
</style>
